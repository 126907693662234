<template>
    <b-card>
        <div class="d-flex justify-content-end">
            <b-button disabled variant="info">
                <feather-icon icon="DownloadIcon"></feather-icon>
                Download Surat Jalan
            </b-button>
        </div>
        <h3 class="text-center mb-2">Surat Jalan Ajuan Barang</h3>
        <ul class="mb-2">
            <li class="mb-1">
                <strong>Salesman:</strong>
            </li>
            <li class="mb-1">
                <strong>Wilayah:</strong>
            </li>
            <li class="mb-1">
                <strong>Tanggal Pengambilan:</strong>
            </li>
            <li class="mb-1">
                <strong>Tanggal Pengembalian:</strong>
            </li>
        </ul>
        <table class="table responsive table-responsive table-bordered table-striped w-100">
            <thead>
                <tr>
                    <th rowspan="2" style="width: 3%" class="text-center valign-center">NO</th>
                    <th rowspan="2" style="vertical-align:center">BRAND</th>
                    <th colspan="3" class="text-center">
                        Pengambilan
                    </th>
                    <th colspan="3" class="text-center">
                        Return
                    </th>
                    <th colspan="3" class="text-center">
                        Pengembalian
                    </th>
                    <th colspan="3" class="text-center">
                        Terjual
                    </th>
                </tr>
                <tr>
                    <th>Dos</th>
                    <th>Bal</th>
                    <th>Slop</th>
                    <th>Dos</th>
                    <th>Bal</th>
                    <th>Slop</th>
                    <th>Dos</th>
                    <th>Bal</th>
                    <th>Slop</th>
                    <th>Dos</th>
                    <th>Bal</th>
                    <th>Slop</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in [0,102,2,3,4,1]" :key="i">
                    <td>{{ (i + 1) }}</td>
                    <td>
                        <strong>GA BOLD20</strong>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </tbody>
        </table>
    </b-card>
</template>
<script>
    import {BCard, BButton} from 'bootstrap-vue'
    export default {
        components: {
            BCard,
            BButton
        }
    }
</script>